import { useState } from "react";
import { IonFooter, IonToolbar, IonTitle } from "@ionic/react";

const Footer = () => {
 const [thisYear] = useState(new Date().getFullYear());
 return (
  <IonFooter>
   <IonToolbar className="h-10 flex flex-col justify-center items-center">
    <IonTitle size="small" className="text-center" color="dark">
     Copyrigths © {thisYear}.Το παρών site αποτελεί πνευματική ιδιοκτησία του
     analyseis.gr
    </IonTitle>
   </IonToolbar>
  </IonFooter>
 );
};

export default Footer;
