import { lazy } from "react";
import { IonRouterOutlet } from "@ionic/react";
import { Route } from "react-router-dom";

const NotFound = lazy(() => import("shared/app/layout/NotFound"));
const Home = lazy(() => import("student/features/Home"));
const Lesson = lazy(() => import("student/features/Lesson/pages/Lesson"));
const Excercise = lazy(
 () => import("student/features/Lesson/pages/Excercises/pages/Excercise")
);
const Grades = lazy(
 () => import("student/features/Lesson/pages/Excercises/pages/Grades")
);

const Documents = lazy(() =>
 import("student/features/Lesson/pages/Documents").then((module) => ({
  default: module.Documents,
 }))
);

const VerifyEmail = lazy(
 () => import("shared/features/User/VerifyEmail/VerifyEmail")
);
const ChangePassword = lazy(
 () => import("shared/features/User/ChangePassword/ChangePassword")
);
const ResetPassword = lazy(
 () => import("shared/features/User/ResetPassword/ResetPassword")
);

const StudentRoutes = () => {
 return (
  <IonRouterOutlet>
   <Route exact path="/" component={Home} />
   <Route exact path="verify-email/:verifyToken" component={VerifyEmail} />
   <Route exact path="/reset-password/:resetLink" component={ResetPassword} />
   <Route exact path="/change-password" component={ChangePassword} />
   <Route
    exact
    path="/excercises/:excerciseId/:randomId"
    component={Excercise}
   />
   <Route
    exact
    path="/excercises/grades/view/:excerciseId"
    component={Grades}
   />
   <Route
    exact
    path="/documents/lesson/:lessonId/chapter/:chapterId"
    component={Documents}
   />
   <Route path="/lesson/:lessonId" component={Lesson} />
   <Route component={NotFound} />
  </IonRouterOutlet>
 );
};

export default StudentRoutes;
