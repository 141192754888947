import { useEffect, useState } from "react";

import store from "shared/utils/store";

const useDarkMode = () => {
 const [isDark, setIsDark] = useState<string>(
  store.get("Analyseis_dark_mode") === undefined
   ? "false"
   : String(store.get("Analyseis_dark_mode"))
 );
 const toggleDarkModeHandler = () => {
  setIsDark((state) => (state === "true" ? "false" : "true"));
  store.set(
   "Analyseis_dark_mode",
   store.get("Analyseis_dark_mode") === "true" ? "false" : "true"
  );
 };

 useEffect(() => {
  if (isDark === "true") {
   return document.body.classList.add("dark");
  }
  document.body.classList.remove("dark");
 }, [isDark]);
 return { toggleDarkModeHandler, isDark };
};

export default useDarkMode;
